import { TextContent } from "../coming-soon-us/_types"

export const TEXT_CONTENT: TextContent = {
  heading: 'Próximamente',
  title: '¡Inter&Co te acompaña en Brasil!',
  description: 'Tu súper app será tu compañera de viaje ideal. Próximamente habrá una serie de funciones nuevas que te ayudarán a aprovechar al máximo tu estancia en Brasil.',
  menuOptions: [
    {
      icon: 'brands/ic_pix',
      title: 'Pix',
      description: 'Transferencias locales sin interrupciones. Podrás enviar y recibir dinero dentro de Brasil.',
    },
    {
      icon: 'brands/ic_inter_loop',
      title: 'Loop',
      description: 'Continuarás ganando puntos Loop durante tu estadía.',
    },
    {
      icon: 'shopping/ic_gift',
      title: 'Marketplace',
      description: 'Descubre más marcas y compra en nuestro mercado de Brasil. ¡Todo en tu súper app!',
    },
    {
      icon: 'finance/ic_cashback',
      title: 'Cashback',
      description: 'Compra tarjetas de regalo de marcas locales y recibe reembolsos. ¡Una manera excelente de ahorrar en tus viajes!',
    },
  ],
}

export const BANNER_IMAGE_URL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/webview_banner_coming_soon_sp/image.webp'
export const FLUXO_TAG = 'COMINGSOON_ACESSO_FLUXO'
export const ACESSO_TAG = 'COMINGSOON_ACESSO'
export const PRODUCT_NAME = 'coming-soon'
