import React from "react"
import Picture from "src/components/Picture"
import CardWithButton from "../coming-soon-us/components/CardWithButton/_CardWithButton"
import { ThemeProvider } from 'styled-components'

import ComingSoonAppHeader from "../coming-soon-us/components/ComingSoonAppHeader/_ComingSoonAppHeader"
import { usePageSliderReducer } from "../coming-soon-us/hooks/_usePageSliderReducer"
import {
  TEXT_CONTENT,
  BANNER_IMAGE_URL,
} from "./_constants"
import { MenuOption } from "../coming-soon-us/_types"
import * as S from "../coming-soon-us/styles"
import { tagBackClick } from "../coming-soon-us/tagHelpers/_index"
import { useInterBridgeInstance } from "../coming-soon-us/hooks/_useInterBridgeInstance"
import defaultTheme from "src/styles/themes/default"

const TesouroMePoupe: React.FC = () => {
  const iWb = useInterBridgeInstance()
  const {
    pageSliderState,
  }: ReturnType<typeof usePageSliderReducer> = usePageSliderReducer()

  const handleBackClick = async () => {
    await iWb?.interWbNavigate.requestGoBack()
    await tagBackClick(iWb)
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <S.MobileFrame>
        <ComingSoonAppHeader heading={TEXT_CONTENT.heading} onBackClick={handleBackClick} />
        <S.ComingSoonAppSection
          id='webview-construa-seu-tesouro'
          isFaqVisible={pageSliderState.forFaq}
          isVideoVisible={pageSliderState.forVideo}
        >
          <div className='banner-block'>
            <Picture
              src={BANNER_IMAGE_URL}
              minHeight={{ sm: '163px' }}
              minWidth={{ sm: '309px' }}
              height='auto'
              width='100%'
              imgClassName='mx-auto'
            />
          </div>
          <h1 className='title'>{TEXT_CONTENT.title}</h1>
          <p className='description'>{TEXT_CONTENT.description}</p>
          <S.CardsGrid>
            {TEXT_CONTENT.menuOptions.map(
                (menuOption: MenuOption, index: number) => (
                  <li key={index}>
                    <CardWithButton
                      icon={menuOption.icon}
                      title={menuOption.title}
                      description={menuOption.description}
                    />
                  </li>
                ),
              )}
          </S.CardsGrid>
        </S.ComingSoonAppSection>
      </S.MobileFrame>
    </ThemeProvider>
  )
}

export default TesouroMePoupe
